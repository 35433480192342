import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { Formik } from "formik";
import axios from "axios";

import Link from "components/common/Link";

import thsLogo from "assets/images/logo-wide.svg";

import Layout from "components/Layout";
import Meta from "components/common/Meta";

const Header = () => {
  return (
    <header>
      <Container>
        <Row>
          <Col className="py-8">
            <img
              src={thsLogo}
              alt="The Hemp Sheet Logo"
              width="500"
              className="mb-8 logo d-block mx-auto"
            />
          </Col>
        </Row>
      </Container>
    </header>
  );
};

const ContactUs = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          discordLink
          twitterUsername
          instagramUsername
          facebookUsername
          contactEmail
        }
      }
    }
  `);
  return (
    <section id="contact" className="py-6 bg-dark text-light">
      <Container>
        <h2 className="text-center mb-6 ">Drop us a line</h2>
        <Row>
          <Col md={6} className="mt-5 mt-md-0">
            <Formik
              initialValues={{}}
              onSubmit={async (values, { setSubmitting, setStatus }) => {
                try {
                  await axios({
                    method: "POST",
                    url: process.env.GATSBY_CONTACT_FORM_URL,
                    data: values,
                  });
                  setStatus({
                    success: "Thanks! We have received your message.",
                  });
                } catch (error) {
                  setStatus({
                    error:
                      "Something went wrong! Try again or contact us directly: hi@thehempsheet.com",
                  });
                }

                setSubmitting(false);
              }}
              render={({
                handleSubmit,
                handleChange,
                status,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit} method="POST">
                  <Form.Row>
                    <Form.Group as={Col} controlId="name">
                      <Form.Control
                        placeholder="Name"
                        name="name"
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="company">
                      <Form.Control
                        name="companyName"
                        placeholder="Company Name"
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Form.Row>

                  <Form.Group controlId="email">
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="reason">
                    <Form.Control
                      as="select"
                      name="reason"
                      onChange={handleChange}
                      className="custom-select"
                      required
                    >
                      <option value="">Select an Inquiry Type...</option>
                      <option value="general">General/Other</option>
                      <option value="siteUpdate">
                        Information Incorrect (Discount codes, etc)
                      </option>
                      <option value="vendor">Vendor Request/Info</option>
                      <option value="marketing">Marketing/Advertising</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="message">
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="messsage"
                      placeholder="How can we help?"
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group>
                    {status && status.success && (
                      <Alert variant="success">{status.success}</Alert>
                    )}
                    {status && status.error && (
                      <Alert variant="danger">{status.error}</Alert>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Button type="submit" disabled={isSubmitting}>
                      Send Message
                    </Button>
                  </Form.Group>
                </Form>
              )}
            />
          </Col>
          <Col md lg={{ offset: 1 }}>
            <h3 className="mt-0">Prefer email?</h3>
            <p>
              Contact us at{" "}
              <Link to={`mailto:${siteMetadata.contactEmail}`}>
                {siteMetadata.contactEmail}
              </Link>{" "}
              and we'll get back to you as soon as we can.
            </p>

            <h3 className="mt-4 mt-lg-5 ">Follow us on Social Media</h3>
            <p>
              Join our
              <Link className="text-discord mx-1" to={siteMetadata.discordLink}>
                <i className="fab fa-discord mr-1" />
                Discord
              </Link>
              server and say hi.
            </p>
            <p>
              Follow us on
              <Link
                className="text-twitter mx-1"
                to={`https://twitter.com/${siteMetadata.twitterUsername}`}
              >
                <i className="fab fa-twitter mr-1" />
                Twitter
              </Link>
              ,
              <Link
                className="text-instagram mx-1"
                to={`https://instagram.com/${siteMetadata.instagramUsername}`}
              >
                <i className="fab fa-instagram mr-1" />
                Instagram
              </Link>
              , or
              <Link
                className="text-facebook mx-1"
                to={`https://facebook.com/${siteMetadata.facebookUsername}`}
              >
                <i className="fab fa-facebook-f mr-1" />
                Facebook
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const AboutSection = () => {
  return (
    <section>
      <Container>
        <Row>
          <Col className="pb-8">
            <Row>
              <Col md="10" lg="8" className="mx-auto">
                <p className="lead">
                  In an emerging industry, clear and transparent information can
                  be hard to come across. That's why we created The Hemp Sheet.
                </p>
                <p>
                  The Hemp Sheet is the source for all things hemp flower. We
                  provide detailed breakdowns of vendors and their products to
                  ensure you can make informed decisions about your purchases.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const CounterBlock = ({ value = 123, title = "Vendors" }) => {
  return (
    <Col className="text-center counter">
      <span className="counter-value text-primary font-weight-bold d-block mb-1">
        {value}+
      </span>
      <p>{title}</p>
    </Col>
  );
};

const Counters = () => (
  <Row>
    <CounterBlock value={101} title="Vendors" />
    <CounterBlock value={101 * 3} title="Products" />
    <CounterBlock value={Math.floor(101 * 1.5)} title="Lab Reports" />
    <CounterBlock value={71} title="Active Discounts" />
  </Row>
);

const CallToActionBar = () => (
  <section className="py-10 bg-light bg-fixed bg-flower bg-shade">
    <Container>
      {/* <Counters /> */}
      <Row className="text-center">
        <Col>
          <Link to="/sheet" external className="btn btn-primary btn-lg mr-4">
            Visit The Sheet
          </Link>
          <Link to="/donate" external className="btn btn-outline-light btn-lg">
            Donate
          </Link>
        </Col>
      </Row>
    </Container>
  </section>
);

const ForVendors = () => (
  <section className="py-6 mb-8">
    <Container>
      <Row>
        <Col md="10" lg="8" className="mx-auto text-center">
          <h2 className="mt-4 mt-lg-5">For Vendors</h2>
          <p>
            Are we missing your store? Do you have a vendor you would like to be
            listed? We will evaluate all requests but do not guarantee we will
            add all of them.
          </p>
          <p>
            <Link className="btn btn-primary" to="/submit-vendor/">
              Submit a Vendor
            </Link>
          </p>
        </Col>
      </Row>
    </Container>
  </section>
);

const IndexPage = () => (
  <Layout>
    <Meta title="CBD Hemp Flower Coupons and Information" />
    <Header />
    <AboutSection />
    <CallToActionBar />
    <ForVendors />
    <ContactUs />
  </Layout>
);

export default IndexPage;
