import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const Link = ({ children, to, external = false, ...other }) => {
  const internal = /^\/(?!\/)/.test(to);

  if (external || !internal) {
    return (
      <OutboundLink href={to} {...other}>
        {children}
      </OutboundLink>
    );
  }
  return (
    <GatsbyLink to={to} {...other}>
      {children}
    </GatsbyLink>
  );
};

export default Link;
